'use server';
import throwError from '@haaretz/s-common-utils/throwError';
import { fetchClusterServerQuery } from '@haaretz/s-server-queries/Cluster.server';

import getSlotComponentByTypename from '../getSlotComponentByTypename';

import type { ClusterSlotTypes } from '@haaretz/s-data-structure-types';
import type { BaseServerActionParams } from '@haaretz/s-types';

const defaultResponse: JSX.Element | null = null;

export default async function loadClusterItems({
  items,
  mainContentId,
}: {
  items: string;
  mainContentId: string;
} & BaseServerActionParams) {
  if (!items) {
    throwError('Cluster items string is required');

    return defaultResponse;
  }

  try {
    const result = await fetchClusterServerQuery({
      items,
      mainContentId,
    })();

    const clusterItems = result.Cluster?.items || [];

    if (!Array.isArray(clusterItems) || clusterItems.length === 0) return defaultResponse;

    return clusterItems.map(element => {
      const Component = getSlotComponentByTypename(element.__typename as ClusterSlotTypes);

      return <Component {...element} key={JSON.stringify(element)} />;
    });
  } catch (error) {
    console.error(`Cluster items action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}
