'use client';
import throwError from '@haaretz/s-common-utils/throwError';
import useIntersectionObserverWithElement from '@haaretz/s-use-intersection-observer/observedElement';
import useOnce from '@haaretz/s-use-once';
import { usePathname, useSearchParams } from 'next/navigation';
import React from 'react';
import s9 from 'style9';

import { useCluster } from './ClusterSlotProvider';
import loadClusterItems from './utils/loadClusterItems';

const c = s9.create({
  obeservedElement: {
    position: 'absolute',
    width: '1px',
    height: '100%',
    pointerEvents: 'none',
    userSelect: 'none',
    touchAction: 'none',
  },
});

function updateRouterStore(routingStateKey: string, page: number, response: React.ReactNode) {
  window.__HTZ.routingState ||= { [routingStateKey]: [] };

  window.__HTZ.routingState[routingStateKey] ||= [];

  window.__HTZ.routingState[routingStateKey][page] = response;
}

interface ClusterSlotProps {
  clusterItemsString: string;
  mainContentId: string;
  page: number;
}

export default function ClusterSlotClient({
  children,
  clusterItemsString,
  mainContentId,
  page,
}: React.PropsWithChildren<ClusterSlotProps>) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [inView, obeservedElement] = useIntersectionObserverWithElement({
    styleExtend: [c.obeservedElement],
  });

  const routingStateKey = `${pathname}-${searchParams.toString()}`;

  const [defaultItems] = React.useState(
    (typeof window !== 'undefined' && window.__HTZ?.routingState?.[routingStateKey]?.[page]) || null
  );

  const [items, setItems] = React.useState<React.ReactNode>(defaultItems);

  const [isPending, setIsPending] = React.useState(false);

  const { page: currentPage, setPage } = useCluster();

  const needToDisplay = !!defaultItems || inView || currentPage >= page;
  const shouldLoadingData = currentPage >= page - 1;

  React.useEffect(() => {
    const pageNumber = page - 1;

    if (needToDisplay && items && !isPending && pageNumber >= currentPage) {
      setPage(page);
    }

    return undefined;
  }, [currentPage, isPending, items, needToDisplay, page, setPage]);

  React.useEffect(() => {
    if (items) {
      updateRouterStore(routingStateKey, page, items);
    }
  }, [items, page, routingStateKey]);

  useOnce(
    () => {
      setIsPending(true);
    },
    (shouldLoadingData || needToDisplay) && !items && !isPending
  );

  useOnce(
    () => {
      loadClusterItems({
        items: clusterItemsString,
        mainContentId,
        postCacheParams: 'sa-cluster-items',
      })
        .then(response => {
          updateRouterStore(routingStateKey, page, response);

          setItems(response);
        })
        .catch(error => {
          throwError(error as Error);
        })
        .finally(() => {
          setIsPending(false);
        });
    },
    (shouldLoadingData || needToDisplay) && !items && isPending
  );

  return needToDisplay && items && !isPending ? (
    items
  ) : (
    <>
      {obeservedElement}
      {children}
    </>
  );
}
